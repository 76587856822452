body {
  background: #f4f6f9;
  min-height: 100vh;
}

#root {
  height: 100%;
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: 300 !important;
}

#main {
  height: 100vh;
}

#login-testing {
  margin-top: 20px;
}

#login-form {
  height: 100%;
}

#login-form .button {
  background-color: #008cff;
}

#login-form .grid.middle.aligned {
  height: 100%;
}

.alert-toast {
  position: absolute;
  z-index: 1002;
  right: 10px;
  top: 10px;
  max-width: 35%;
}
